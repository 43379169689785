<template>
    <div>
        <create-dialog :value="dialog"
        @close-dialog="dialog=false, item=null"
        :item="item" @fetchData="fetchData" @setSelect="setSelect"></create-dialog>
        <v-card>
            <v-toolbar color="white" flat>
                <v-toolbar-title class="mx-4">Danh sách phiếu {{this.USER_ID}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="orange" icon @click="getPhieu"><v-icon>mdi-refresh</v-icon></v-btn>
                
                <!-- //actions -->
                <!-- <v-menu
                    left
                    bottom offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="success" class="ml-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            Hành động<v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                    <v-list-item @click="dialog=true">
                        <v-list-item-title>Thêm phiếu <v-icon color="primary" right>mdi-plus</v-icon></v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-menu> -->
            </v-toolbar>
            
            <v-card-title>
                <v-row justify="start">
                    <v-col cols="12" md="3">
                        <v-text-field type="date" hide-details outlined dense v-model="filterFromDate" label="Từ ngày" ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <!-- todate -->
                        <v-text-field type="date" hide-details outlined dense v-model="filterToDate" label="Từ ngày" ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <!-- status -->
                        <v-select clearable :items="statuses" outlined hide-details v-model="filterStatus" dense label="Trạng thái">
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                        <!-- chinhanh -->
                        <v-select clearable :items="chinhanhs" outlined hide-details v-model="filterChiNhanh" dense label="Chi nhánh"></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                        <!-- Nhan hang -->
                        <v-text-field hide-details outlined dense v-model="filterNhanHang" label="Nhãn hàng" persistent-placeholder></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <!-- Nhan hang -->
                        <v-text-field hide-details outlined dense v-model="filterNcc" label="Nhà cung cấp" persistent-placeholder></v-text-field>
                    </v-col>
                    
                    <!-- <v-col cols="12" md="3">
                        <v-text-field clearable outlined hide-details v-model="filterId" dense label="ID phiếu" persistent-placeholder></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field clearable outlined hide-details v-model="filterMaHang" dense label="Mã Hàng" persistent-placeholder></v-text-field>
                    </v-col> -->

                    <v-col cols="12" md="12">
                        <v-text-field v-model="globalSearchTemp" hide-details outlined dense
                             placeholder="" persistent-placeholder label="Tìm tất cả" ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="red--text">
                <b>CẬP NHẬT:</b>
                <ul>
                    <li>
                        <small>
                            <i>Tab <u>ASM xác nhận</u> đã được gộp vào tab chờ xác nhận, và sắp xếp ở thứ tự đầu tiên</i>
                        </small>
                    </li>
                    <li>
                        <small>
                            <i>Nút <u>Xuất excel</u> Xuất file excel ở mỗi tab</i>
                        </small>
                    </li>
                </ul>
            </v-card-text>
        </v-card>
        <v-row class="mt-5">
            <v-col cols="12">
                <v-card>
                    <v-tabs slider-color="orange" >
                        <v-tab class="primary--text">Chờ xác nhận ({{ itemsCxnLength }})</v-tab>
                        <v-tab class="success--text">Đã xác nhận ({{ itemsDxn.length }})</v-tab>
                        <v-tab class="error--text">Không đặt được ({{ itemsKdd.length }})</v-tab>
                        <v-tab class="indigo--text">Cần ASM duyệt ({{ itemsKhd.length }})</v-tab>
                        <!-- <v-tab class="pink--text">ASM đã duyệt ({{ itemsAsm.length }})</v-tab> -->

                        <v-tab-item>
                            <v-container fluid>
                                <v-data-table :items="itemsCxn" :headers="headers1"  height="calc(100vh - 300px)"  fixed-header @pagination="setItemsCxnLength"
                                :footer-props="{
                                    itemsPerPageOptions:[
                                        50, 100, 400
                                    ]
                                }"
                                :search="globalSearch" >
                                    <template v-slot:top>
                                        <v-card-title>
                                            <v-spacer></v-spacer>
                                            <vue-excel-xlsx
                                                :data="itemsCxn"
                                                :columns = "convertToExcelLabel(headers1)"
                                                :file-name="'DU_LIEU_CHO_XAC_NHAN'"
                                                :file-type="'xlsx'"
                                                :sheet-name="'sheetname'"
                                                >
                                                    <v-btn color="green" small dark> <v-icon left>mdi-microsoft-excel</v-icon>Xuất dữ liệu</v-btn>
                                            </vue-excel-xlsx>
                                        </v-card-title>
                                    </template>
                                    <template v-slot:[`item.actions`]="{item}" >
                                        <v-menu dense
                                            left
                                            bottom offset-y
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn color="primary" x-small
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    Thao tác<v-icon>mdi-chevron-down</v-icon>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                
                                                <v-list-item @click="edit(item.id)" dense>
                                                    <v-list-item-icon>
                                                        <v-icon color="primary">mdi-cursor-text</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Chi tiết </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="exportTemplate(item)" dense>
                                                    <v-list-item-icon>
                                                        <v-icon color="green">mdi-microsoft-excel</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Xuất file</v-list-item-title>
                                                </v-list-item>
                                                
                                                <v-list-item @click="publish(item.id)" dense>
                                                    <v-list-item-icon>
                                                        <v-icon color="warning">mdi-earth-arrow-right</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>{{item.isPublished ==1 ? 'Thu hồi' : 'Xuất bản'}}</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="dialogDelete=true, targetItem=item.id" dense>
                                                    <v-list-item-icon>
                                                        <v-icon color="error">mdi-delete</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Xóa</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <!-- <edit-button @click="edit(item)"></edit-button>
                                        <delete-button @click="deleteItem(item)"></delete-button> -->
                                    </template>

                                    <template v-slot:[`item.TinhTrang`]="{item}" >
                                        
                                        <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small
                                            :color="statusColors[item.TinhTrang]"
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            {{item.TinhTrang}}
                                            <v-icon right>mdi-menu-down</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-list>
                                            <v-list-item @click="openBottom(item, status)"
                                            v-for="(status, index) in statuses.filter(x=>x != item.TinhTrang && x != 'ASM đã duyệt' && x != 'Chờ xác nhận')"
                                            :key="index"
                                            >
                                                <v-list-item-title :class="`${statusColors[status]}--text font-weight-medium`">{{ status }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                        </v-menu>
                                        <p class="mt-3" v-if="!!item.DuTruDuoc || !!item.DuTruKhongDuoc">
                                            <span v-if="item.DuTruDuoc" class="green--text text--darken-3"><b>Ghi chú:</b> {{item.DuTruDuoc}}</span>
                                            <span v-if="item.DuTruKhongDuoc" class="red--text text--darken-3"><b>Ghi chú: </b> {{item.DuTruKhongDuoc}}</span>
                                        </p>
                                    </template>

                                    <template v-slot:[`item.note`]="{item}" >
                                        <span v-if="item.DuTruDuoc" class="green--text text--darken-3"><b>Chấp nhận:</b> {{item.DuTruDuoc}}</span>
                                        <span v-if="item.DuTruKhongDuoc" class="red--text text--darken-3"><b>Không đặt được: </b> {{item.DuTruKhongDuoc}}</span>
                                    </template>
                                    
                                    <template v-slot:[`item.LinkAnh`]="{item}" >
                                        {{convertImage(item.LinkAnh)}}
                                    </template>

                                    <template v-slot:[`item.TenHang`]="{item}" >
                                        <a v-if="convertImage(item.LinkAnh)" :href="convertImage(item.LinkAnh)" target="_blank">
                                            <img :src="convertImage(item.LinkAnh)" alt="" srcset="" width="200px" height="200px" style="object-fit: cover;">
                                        </a>
                                        <h4>{{item.TenHang}}</h4>
                                        
                                        <!-- <p class="primary--text"><b>Ghi chú: </b> {{item.GhiChu}}</p> -->
                                    </template>
                                    
                                    <template v-slot:[`item.KhongHoaDon`]="{item}" >
                                        <v-checkbox :input-value="item.KhongHoaDon" @change="setKhongHoaDon(item, $event)"></v-checkbox>
                                    </template>
                                    <template v-slot:[`item.GhiChu`]="{item}">
                                        <b v-if="item.GhiChu">Ghi chú: </b> {{item.GhiChu}}
                                        <ul>
                                            <li v-if="item.LoaiHang">
                                                <b>Loại hàng: </b> {{item.LoaiHang}}
                                            </li>
                                            <li v-if="item.LoaiYeuCau">
                                                <b>Loại yêu cầu: </b> {{item.LoaiYeuCau}}
                                            </li>
                                            <li v-if="item.TrungBinhBan">
                                                <b>T.bình bán: </b> {{item.TrungBinhBan}}
                                            </li>
                                            <li v-if="item.NgayGiaoKhach">
                                                <b>Ngày giao khách: </b> {{item.NgayGiaoKhach}}
                                            </li>
                                        </ul>
                                    </template>

                                </v-data-table>
                            </v-container>
                        </v-tab-item>
                        <v-tab-item>
                            <v-container fluid>
                                <v-data-table :items="itemsDxn" :headers="headers1"  height="calc(100vh - 300px)"  fixed-header
                                :search="globalSearch" >
                                    <template v-slot:top>
                                        <v-card-title>
                                            <v-spacer></v-spacer>
                                            <vue-excel-xlsx
                                                :data="itemsDxn"
                                                :columns = "convertToExcelLabel(headers2)"
                                                :file-name="'DU_LIEU_DA_XAC_NHAN'"
                                                :file-type="'xlsx'"
                                                :sheet-name="'sheetname'"
                                                >
                                                    <v-btn color="green" small dark> <v-icon left>mdi-microsoft-excel</v-icon>Xuất dữ liệu</v-btn>
                                            </vue-excel-xlsx>
                                        </v-card-title>
                                    </template>
                                    <template v-slot:[`item.actions`]="{item}" >
                                        <v-menu dense
                                            left
                                            bottom offset-y
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn color="primary" x-small
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    Thao tác<v-icon>mdi-chevron-down</v-icon>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                
                                                <v-list-item @click="edit(item.id)" dense>
                                                    <v-list-item-icon>
                                                        <v-icon color="primary">mdi-cursor-text</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Chi tiết </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="exportTemplate(item)" dense>
                                                    <v-list-item-icon>
                                                        <v-icon color="green">mdi-microsoft-excel</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Xuất file</v-list-item-title>
                                                </v-list-item>
                                                
                                                <v-list-item @click="publish(item.id)" dense>
                                                    <v-list-item-icon>
                                                        <v-icon color="warning">mdi-earth-arrow-right</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>{{item.isPublished ==1 ? 'Thu hồi' : 'Xuất bản'}}</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="dialogDelete=true, targetItem=item.id" dense>
                                                    <v-list-item-icon>
                                                        <v-icon color="error">mdi-delete</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Xóa</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <!-- <edit-button @click="edit(item)"></edit-button>
                                        <delete-button @click="deleteItem(item)"></delete-button> -->
                                    </template>

                                    <template v-slot:[`item.TinhTrang`]="{item}" >
                                        
                                        <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small
                                            :color="statusColors[item.TinhTrang]"
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            {{item.TinhTrang}}
                                            <v-icon right>mdi-menu-down</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-list>
                                            <v-list-item @click="openBottom(item, 'Đã xác nhận')" >
                                                <v-list-item-title :class="`success--text font-weight-medium`">Sửa ghi chú</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                        </v-menu>
                                        <p class="mt-3" v-if="!!item.DuTruDuoc || !!item.DuTruKhongDuoc">
                                            <span v-if="item.DuTruDuoc" class="green--text text--darken-3"><b>Ghi chú:</b> {{item.DuTruDuoc}}</span>
                                            <span v-if="item.DuTruKhongDuoc" class="red--text text--darken-3"><b>Ghi chú: </b> {{item.DuTruKhongDuoc}}</span>
                                        </p>
                                    </template>

                                    <template v-slot:[`item.note`]="{item}" >
                                        <span v-if="item.DuTruDuoc" class="green--text text--darken-3"><b>Chấp nhận:</b> {{item.DuTruDuoc}}</span>
                                        <span v-if="item.DuTruKhongDuoc" class="red--text text--darken-3"><b>Không đặt được: </b> {{item.DuTruKhongDuoc}}</span>
                                    </template>
                                    
                                    <template v-slot:[`item.LinkAnh`]="{item}" >
                                        {{convertImage(item.LinkAnh)}}
                                    </template>

                                    <template v-slot:[`item.TenHang`]="{item}" >
                                        <a v-if="convertImage(item.LinkAnh)" :href="convertImage(item.LinkAnh)" target="_blank">
                                            <img :src="convertImage(item.LinkAnh)" alt="" srcset="" width="200px" height="200px" style="object-fit: cover;">
                                        </a>
                                        <h4>{{item.TenHang}}</h4>
                                        
                                        <!-- <p class="primary--text"><b>Ghi chú: </b> {{item.GhiChu}}</p> -->
                                    </template>
                                    
                                    <template v-slot:[`item.KhongHoaDon`]="{item}" >
                                        <v-checkbox :input-value="item.KhongHoaDon" @change="setKhongHoaDon(item, $event)"></v-checkbox>
                                    </template>
                                    <template v-slot:[`item.GhiChu`]="{item}">
                                        <b v-if="item.GhiChu">Ghi chú: </b> {{item.GhiChu}}
                                        <ul>
                                            <li v-if="item.LoaiHang">
                                                <b>Loại hàng: </b> {{item.LoaiHang}}
                                            </li>
                                            <li v-if="item.LoaiYeuCau">
                                                <b>Loại yêu cầu: </b> {{item.LoaiYeuCau}}
                                            </li>
                                            <li v-if="item.TrungBinhBan">
                                                <b>T.bình bán: </b> {{item.TrungBinhBan}}
                                            </li>
                                            <li v-if="item.NgayGiaoKhach">
                                                <b>Ngày giao khách: </b> {{item.NgayGiaoKhach}}
                                            </li>
                                        </ul>
                                    </template>

                                </v-data-table>
                            </v-container>
                        </v-tab-item>
                        <v-tab-item>
                            <v-container fluid>
                                <v-data-table :items="itemsKdd" :headers="headers1"  height="calc(100vh - 300px)"  fixed-header
                                :search="globalSearch" >
                                    <template v-slot:top>
                                        <v-card-title>
                                            <v-spacer></v-spacer>
                                            <vue-excel-xlsx
                                                :data="itemsKdd"
                                                :columns = "convertToExcelLabel(headers1)"
                                                :file-name="'DU_LIEU_KHONG_XAC_NHAN'"
                                                :file-type="'xlsx'"
                                                :sheet-name="'sheetname'"
                                                >
                                                    <v-btn color="green" small dark> <v-icon left>mdi-microsoft-excel</v-icon>Xuất dữ liệu</v-btn>
                                            </vue-excel-xlsx>
                                        </v-card-title>
                                    </template>
                                    <template v-slot:[`item.actions`]="{item}" >
                                        <v-menu dense
                                            left
                                            bottom offset-y
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn color="primary" x-small
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    Thao tác<v-icon>mdi-chevron-down</v-icon>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                
                                                <v-list-item @click="edit(item.id)" dense>
                                                    <v-list-item-icon>
                                                        <v-icon color="primary">mdi-cursor-text</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Chi tiết </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="exportTemplate(item)" dense>
                                                    <v-list-item-icon>
                                                        <v-icon color="green">mdi-microsoft-excel</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Xuất file</v-list-item-title>
                                                </v-list-item>
                                                
                                                <v-list-item @click="publish(item.id)" dense>
                                                    <v-list-item-icon>
                                                        <v-icon color="warning">mdi-earth-arrow-right</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>{{item.isPublished ==1 ? 'Thu hồi' : 'Xuất bản'}}</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="dialogDelete=true, targetItem=item.id" dense>
                                                    <v-list-item-icon>
                                                        <v-icon color="error">mdi-delete</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Xóa</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <!-- <edit-button @click="edit(item)"></edit-button>
                                        <delete-button @click="deleteItem(item)"></delete-button> -->
                                    </template>

                                    <template v-slot:[`item.TinhTrang`]="{item}" >
                                        
                                        <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small
                                            :color="statusColors[item.TinhTrang]"
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            {{item.TinhTrang}}
                                            <v-icon right>mdi-menu-down</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-list>
                                            <v-list-item @click="openBottom(item, 'Không đặt được')" >
                                                <v-list-item-title :class="`error--text font-weight-medium`">Sửa ghi chú</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                        </v-menu>
                                        <p class="mt-3" v-if="!!item.DuTruDuoc || !!item.DuTruKhongDuoc">
                                            <span v-if="item.DuTruDuoc" class="green--text text--darken-3"><b>Ghi chú:</b> {{item.DuTruDuoc}}</span>
                                            <span v-if="item.DuTruKhongDuoc" class="red--text text--darken-3"><b>Ghi chú: </b> {{item.DuTruKhongDuoc}}</span>
                                        </p>
                                    </template>

                                    <template v-slot:[`item.note`]="{item}" >
                                        <span v-if="item.DuTruDuoc" class="green--text text--darken-3"><b>Chấp nhận:</b> {{item.DuTruDuoc}}</span>
                                        <span v-if="item.DuTruKhongDuoc" class="red--text text--darken-3"><b>Không đặt được: </b> {{item.DuTruKhongDuoc}}</span>
                                    </template>
                                    
                                    <template v-slot:[`item.LinkAnh`]="{item}" >
                                        {{convertImage(item.LinkAnh)}}
                                    </template>

                                    <template v-slot:[`item.TenHang`]="{item}" >
                                        <a v-if="convertImage(item.LinkAnh)" :href="convertImage(item.LinkAnh)" target="_blank">
                                            <img :src="convertImage(item.LinkAnh)" alt="" srcset="" width="200px" height="200px" style="object-fit: cover;">
                                        </a>
                                        <h4>{{item.TenHang}}</h4>
                                        
                                        <!-- <p class="primary--text"><b>Ghi chú: </b> {{item.GhiChu}}</p> -->
                                    </template>
                                    
                                    <template v-slot:[`item.KhongHoaDon`]="{item}" >
                                        <v-checkbox :input-value="item.KhongHoaDon" @change="setKhongHoaDon(item, $event)"></v-checkbox>
                                    </template>
                                    <template v-slot:[`item.GhiChu`]="{item}">
                                        <b v-if="item.GhiChu">Ghi chú: </b> {{item.GhiChu}}
                                        <ul>
                                            <li v-if="item.LoaiHang">
                                                <b>Loại hàng: </b> {{item.LoaiHang}}
                                            </li>
                                            <li v-if="item.LoaiYeuCau">
                                                <b>Loại yêu cầu: </b> {{item.LoaiYeuCau}}
                                            </li>
                                            <li v-if="item.TrungBinhBan">
                                                <b>T.bình bán: </b> {{item.TrungBinhBan}}
                                            </li>
                                            <li v-if="item.NgayGiaoKhach">
                                                <b>Ngày giao khách: </b> {{item.NgayGiaoKhach}}
                                            </li>
                                        </ul>
                                    </template>

                                </v-data-table>
                            </v-container>
                        </v-tab-item>
                        <v-tab-item>
                            <v-container fluid>
                                <v-data-table :items="itemsKhd" :headers="headers1"  height="calc(100vh - 300px)"  fixed-header
                                :search="globalSearch" >
                                    <template v-slot:top>
                                        <v-card-title>
                                            <v-spacer></v-spacer>
                                            <vue-excel-xlsx
                                                :data="itemsKhd"
                                                :columns = "convertToExcelLabel(headers1)"
                                                :file-name="'DU_LIEU_CHO_XAC_NHAN'"
                                                :file-type="'xlsx'"
                                                :sheet-name="'sheetname'"
                                                >
                                                    <v-btn color="green" small dark> <v-icon left>mdi-microsoft-excel</v-icon>Xuất dữ liệu</v-btn>
                                            </vue-excel-xlsx>
                                        </v-card-title>
                                    </template>
                                    <template v-slot:[`item.actions`]="{item}" >
                                        <v-menu dense
                                            left
                                            bottom offset-y
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn color="primary" x-small
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    Thao tác<v-icon>mdi-chevron-down</v-icon>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                
                                                <v-list-item @click="edit(item.id)" dense>
                                                    <v-list-item-icon>
                                                        <v-icon color="primary">mdi-cursor-text</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Chi tiết </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="exportTemplate(item)" dense>
                                                    <v-list-item-icon>
                                                        <v-icon color="green">mdi-microsoft-excel</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Xuất file</v-list-item-title>
                                                </v-list-item>
                                                
                                                <v-list-item @click="publish(item.id)" dense>
                                                    <v-list-item-icon>
                                                        <v-icon color="warning">mdi-earth-arrow-right</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>{{item.isPublished ==1 ? 'Thu hồi' : 'Xuất bản'}}</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="dialogDelete=true, targetItem=item.id" dense>
                                                    <v-list-item-icon>
                                                        <v-icon color="error">mdi-delete</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Xóa</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <!-- <edit-button @click="edit(item)"></edit-button>
                                        <delete-button @click="deleteItem(item)"></delete-button> -->
                                    </template>

                                    <template v-slot:[`item.TinhTrang`]="{item}" >
                                        
                                        <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small
                                            :color="statusColors[item.TinhTrang]"
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            {{item.TinhTrang}}
                                            <v-icon right>mdi-menu-down</v-icon>
                                            </v-btn>
                                        </template>

                                        <!-- <v-list>
                                            <v-list-item @click="openBottom(item, status)"
                                            v-for="(status, index) in statuses.filter(x=>x != item.TinhTrang)"
                                            :key="index"
                                            >
                                                <v-list-item-title :class="`${statusColors[status]}--text font-weight-medium`">{{ status }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list> -->
                                        </v-menu>
                                        <p class="mt-3" v-if="!!item.DuTruDuoc || !!item.DuTruKhongDuoc">
                                            <span v-if="item.DuTruDuoc" class="green--text text--darken-3"><b>Ghi chú:</b> {{item.DuTruDuoc}}</span>
                                            <span v-if="item.DuTruKhongDuoc" class="red--text text--darken-3"><b>Ghi chú: </b> {{item.DuTruKhongDuoc}}</span>
                                        </p>
                                    </template>

                                    <template v-slot:[`item.note`]="{item}" >
                                        <span v-if="item.DuTruDuoc" class="green--text text--darken-3"><b>Chấp nhận:</b> {{item.DuTruDuoc}}</span>
                                        <span v-if="item.DuTruKhongDuoc" class="red--text text--darken-3"><b>Không đặt được: </b> {{item.DuTruKhongDuoc}}</span>
                                    </template>
                                    
                                    <template v-slot:[`item.LinkAnh`]="{item}" >
                                        {{convertImage(item.LinkAnh)}}
                                    </template>

                                    <template v-slot:[`item.TenHang`]="{item}" >
                                        <a v-if="convertImage(item.LinkAnh)" :href="convertImage(item.LinkAnh)" target="_blank">
                                            <img :src="convertImage(item.LinkAnh)" alt="" srcset="" width="200px" height="200px" style="object-fit: cover;">
                                        </a>
                                        <h4>{{item.TenHang}}</h4>
                                        
                                        <!-- <p class="primary--text"><b>Ghi chú: </b> {{item.GhiChu}}</p> -->
                                    </template>
                                    
                                    <template v-slot:[`item.KhongHoaDon`]="{item}" >
                                        <v-checkbox :input-value="item.KhongHoaDon" @change="setKhongHoaDon(item, $event)"></v-checkbox>
                                    </template>
                                    <template v-slot:[`item.GhiChu`]="{item}">
                                        <b v-if="item.GhiChu">Ghi chú: </b> {{item.GhiChu}}
                                        <ul>
                                            <li v-if="item.LoaiHang">
                                                <b>Loại hàng: </b> {{item.LoaiHang}}
                                            </li>
                                            <li v-if="item.LoaiYeuCau">
                                                <b>Loại yêu cầu: </b> {{item.LoaiYeuCau}}
                                            </li>
                                            <li v-if="item.TrungBinhBan">
                                                <b>T.bình bán: </b> {{item.TrungBinhBan}}
                                            </li>
                                            <li v-if="item.NgayGiaoKhach">
                                                <b>Ngày giao khách: </b> {{item.NgayGiaoKhach}}
                                            </li>
                                        </ul>
                                    </template>

                                </v-data-table>
                            </v-container>
                        </v-tab-item>
                        <!-- <v-tab-item>
                            <v-container fluid>
                                <v-data-table :items="itemsAsm" :headers="headers1"  height="calc(100vh - 300px)"  fixed-header
                                :search="globalSearch" >
                                    <template v-slot:[`item.actions`]="{item}" >
                                        <v-menu dense
                                            left
                                            bottom offset-y
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn color="primary" x-small
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    Thao tác<v-icon>mdi-chevron-down</v-icon>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                
                                                <v-list-item @click="edit(item.id)" dense>
                                                    <v-list-item-icon>
                                                        <v-icon color="primary">mdi-cursor-text</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Chi tiết </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="exportTemplate(item)" dense>
                                                    <v-list-item-icon>
                                                        <v-icon color="green">mdi-microsoft-excel</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Xuất file</v-list-item-title>
                                                </v-list-item>
                                                
                                                <v-list-item @click="publish(item.id)" dense>
                                                    <v-list-item-icon>
                                                        <v-icon color="warning">mdi-earth-arrow-right</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>{{item.isPublished ==1 ? 'Thu hồi' : 'Xuất bản'}}</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="dialogDelete=true, targetItem=item.id" dense>
                                                    <v-list-item-icon>
                                                        <v-icon color="error">mdi-delete</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Xóa</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </template>

                                    <template v-slot:[`item.TinhTrang`]="{item}" >
                                        
                                        <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small
                                            :color="statusColors[item.TinhTrang]"
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            {{item.TinhTrang}}
                                            <v-icon right>mdi-menu-down</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-list>
                                            <v-list-item @click="openBottom(item, status)"
                                            v-for="(status, index) in statuses.filter(x=>x != item.TinhTrang && x != 'ASM cần duyệt' && x != 'Chờ xác nhận')"
                                            :key="index"
                                            >
                                                <v-list-item-title :class="`${statusColors[status]}--text font-weight-medium`">{{ status }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                        </v-menu>
                                        <p class="mt-3" v-if="!!item.DuTruDuoc || !!item.DuTruKhongDuoc">
                                            <span v-if="item.DuTruDuoc" class="green--text text--darken-3"><b>Ghi chú:</b> {{item.DuTruDuoc}}</span>
                                            <span v-if="item.DuTruKhongDuoc" class="red--text text--darken-3"><b>Ghi chú: </b> {{item.DuTruKhongDuoc}}</span>
                                        </p>
                                    </template>

                                    <template v-slot:[`item.note`]="{item}" >
                                        <span v-if="item.DuTruDuoc" class="green--text text--darken-3"><b>Chấp nhận:</b> {{item.DuTruDuoc}}</span>
                                        <span v-if="item.DuTruKhongDuoc" class="red--text text--darken-3"><b>Không đặt được: </b> {{item.DuTruKhongDuoc}}</span>
                                    </template>
                                    
                                    <template v-slot:[`item.LinkAnh`]="{item}" >
                                        {{convertImage(item.LinkAnh)}}
                                    </template>

                                    <template v-slot:[`item.TenHang`]="{item}" >
                                        <a v-if="convertImage(item.LinkAnh)" :href="convertImage(item.LinkAnh)" target="_blank">
                                            <img :src="convertImage(item.LinkAnh)" alt="" srcset="" width="200px" height="200px" style="object-fit: cover;">
                                        </a>
                                        <h4>{{item.TenHang}}</h4>
                                        
                                    </template>
                                    
                                    <template v-slot:[`item.KhongHoaDon`]="{item}" >
                                        <v-checkbox :input-value="item.KhongHoaDon" @change="setKhongHoaDon(item, $event)"></v-checkbox>
                                    </template>
                                    <template v-slot:[`item.GhiChu`]="{item}">
                                        <b v-if="item.GhiChu">Ghi chú: </b> {{item.GhiChu}}
                                        <ul>
                                            <li v-if="item.LoaiHang">
                                                <b>Loại hàng: </b> {{item.LoaiHang}}
                                            </li>
                                            <li v-if="item.LoaiYeuCau">
                                                <b>Loại yêu cầu: </b> {{item.LoaiYeuCau}}
                                            </li>
                                            <li v-if="item.TrungBinhBan">
                                                <b>T.bình bán: </b> {{item.TrungBinhBan}}
                                            </li>
                                            <li v-if="item.NgayGiaoKhach">
                                                <b>Ngày giao khách: </b> {{item.NgayGiaoKhach}}
                                            </li>
                                        </ul>
                                    </template>

                                </v-data-table>
                            </v-container>
                        </v-tab-item> -->
                    </v-tabs>
                </v-card>

            </v-col>
            <!-- <v-col cols="12">
                <v-card>
                    <v-data-table :items="items1" :headers="headers1"  height="calc(100vh - 300px)"  fixed-header
                    :search="globalSearch" >
                        <template v-slot:[`item.actions`]="{item}" >
                            <v-menu dense
                                left
                                bottom offset-y
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary" x-small
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        Thao tác<v-icon>mdi-chevron-down</v-icon>
                                    </v-btn>
                                </template>

                                <v-list>
                                    
                                    <v-list-item @click="edit(item.id)" dense>
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-cursor-text</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>Chi tiết </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="exportTemplate(item)" dense>
                                        <v-list-item-icon>
                                            <v-icon color="green">mdi-microsoft-excel</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>Xuất file</v-list-item-title>
                                    </v-list-item>
                                    
                                    <v-list-item @click="publish(item.id)" dense>
                                        <v-list-item-icon>
                                            <v-icon color="warning">mdi-earth-arrow-right</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>{{item.isPublished ==1 ? 'Thu hồi' : 'Xuất bản'}}</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="dialogDelete=true, targetItem=item.id" dense>
                                        <v-list-item-icon>
                                            <v-icon color="error">mdi-delete</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>Xóa</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>

                        <template v-slot:[`item.TinhTrang`]="{item}" >
                            
                            <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small
                                :color="statusColors[item.TinhTrang]"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                >
                                {{item.TinhTrang}}
                                <v-icon right>mdi-menu-down</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item @click="openBottom(item, status)"
                                v-for="(status, index) in statuses.filter(x=>x != item.TinhTrang)"
                                :key="index"
                                >
                                    <v-list-item-title :class="`${statusColors[status]}--text font-weight-medium`">{{ status }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                            </v-menu>
                            <p class="mt-3" v-if="!!item.DuTruDuoc || !!item.DuTruKhongDuoc">
                                <span v-if="item.DuTruDuoc" class="green--text text--darken-3"><b>Ghi chú:</b> {{item.DuTruDuoc}}</span>
                                <span v-if="item.DuTruKhongDuoc" class="red--text text--darken-3"><b>Ghi chú: </b> {{item.DuTruKhongDuoc}}</span>
                            </p>
                        </template>

                        <template v-slot:[`item.note`]="{item}" >
                            <span v-if="item.DuTruDuoc" class="green--text text--darken-3"><b>Chấp nhận:</b> {{item.DuTruDuoc}}</span>
                            <span v-if="item.DuTruKhongDuoc" class="red--text text--darken-3"><b>Không đặt được: </b> {{item.DuTruKhongDuoc}}</span>
                        </template>
                        
                        <template v-slot:[`item.LinkAnh`]="{item}" >
                            {{convertImage(item.LinkAnh)}}
                        </template>

                        <template v-slot:[`item.TenHang`]="{item}" >
                            <a v-if="convertImage(item.LinkAnh)" :href="convertImage(item.LinkAnh)" target="_blank">
                                <img :src="convertImage(item.LinkAnh)" alt="" srcset="" width="200px" height="200px" style="object-fit: cover;">
                            </a>
                            <h4>{{item.TenHang}}</h4>
                            
                        </template>
                        
                        <template v-slot:[`item.KhongHoaDon`]="{item}" >
                            <v-checkbox :input-value="item.KhongHoaDon" @change="setKhongHoaDon(item, $event)"></v-checkbox>
                        </template>
                        <template v-slot:[`item.GhiChu`]="{item}">
                            <b v-if="item.GhiChu">Ghi chú: </b> {{item.GhiChu}}
                            <ul>
                                <li v-if="item.LoaiHang">
                                    <b>Loại hàng: </b> {{item.LoaiHang}}
                                </li>
                                <li v-if="item.LoaiYeuCau">
                                    <b>Loại yêu cầu: </b> {{item.LoaiYeuCau}}
                                </li>
                                <li v-if="item.TrungBinhBan">
                                    <b>T.bình bán: </b> {{item.TrungBinhBan}}
                                </li>
                                <li v-if="item.NgayGiaoKhach">
                                    <b>Ngày giao khách: </b> {{item.NgayGiaoKhach}}
                                </li>
                            </ul>
                        </template>

                    </v-data-table>
                </v-card>
            </v-col> -->
            
        </v-row>
        
        <!-- ************** BOTTOM SHEET  *************** -->
        <v-bottom-sheet v-model="bottomSheet" persistent max-width="600px" hide-overlay>
            <v-card
                v-if="item1SelectObj"
                class="" 
                height="300px"
            >
                <v-card-title v-if="bottomSheetType!='RETURN'" :class="`font-weight-bold ${bottomSheetType == 'ACCEPT'? 'green' : ( bottomSheetType == 'DENY'? 'red': '')}--text text--darken-2`"><v-spacer></v-spacer>{{bottomSheetType == 'ACCEPT'? 'Xác nhận' : ( bottomSheetType == 'DENY'? 'Từ chối': '')}} phiếu {{item1SelectObj.ID}}<v-spacer></v-spacer></v-card-title>
                <v-card-title v-if="bottomSheetType=='RETURN'" class="font-weight-bold primary--text"> <v-spacer></v-spacer> Trả về phiếu phiếu {{item1SelectObj.ID}}<v-spacer></v-spacer></v-card-title>
                <v-card-text v-if="bottomSheetType!='RETURN'">
                    <v-row>
                        <v-col cols="12" class="">
                            <label for="bottom_note" class="font-weight-bold text-left">Ghi chú</label>
                            <v-textarea id="bottom_note" rows="4" solo hide-details placeholder="Gõ enter để LƯU" v-model="note">

                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mt-3" outlined color="success" @click="setStatusNote" >
                    Lưu
                    </v-btn>

                    <v-btn class="mt-3" color="error" text @click="closeBottom" >
                    Đóng
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-bottom-sheet>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này? ID: {{targetItem}}</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteTemplate">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="loadingDialog" width="300px" persistent>
            <v-card>
                <v-card-title class="text-center">
                    <v-spacer></v-spacer>
                    Đang xuất file
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="text-center">
                    <v-progress-circular
                    size="70"
                    indeterminate
                    color="orange"
                    ></v-progress-circular>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import CreateDialog from './Create.vue';
import http from '@/components/services/http-common';
import moment from 'moment/moment';
import api from './services/api.js'
import _ from 'lodash';

export default {
    components:{
        "create-dialog": CreateDialog,
    },
    computed: {
        
        selectedTemplate() {
            let rslt = this.item1Select.length>0 ? ` ${this.item1Select[0].id }`: ""

            return rslt
        },
        chinhanhs(){
            return [...new Set(this.items1.map(obj => obj.ChiNhanhDuTru))]
        },
        // itemsCxn() {
        //     return this.items1.filter(x=>x.TinhTrang == 'Chờ xác nhận')
        // },
        // itemsDxn() {
        //     return this.items1.filter(x=>x.TinhTrang == 'Đã xác nhận')
        // },
        // itemsKdd() {
        //     return this.items1.filter(x=>x.TinhTrang == 'Không đặt được')
        // },
        // itemsAsm() {
        //     return this.items1.filter(x=>x.TinhTrang == 'ASM đã duyệt')
        // }
    },
    watch:{
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        month(val)
        {
            this.fetchData();
        },
        item1Select() {
            if(this.selectedTemplate != ""){
                this.getQuestions()
            } else{
                this.questions=[]
            }
        },
        filterFromDate() {
            this.getPhieu()
        },
        filterToDate() {
            this.getPhieu()
        },
        
        globalSearchTemp: {
            handler: _.debounce(function(value){
                    this.globalSearch = value
                }, 700),
            deep: true,
        },
    },
    data () {
        return {
            TOKEN: null,
            USER_ID: null,
            month: moment().startOf("months").format("yyyy-MM"),
            statuses: ['Chờ xác nhận','Đã xác nhận','Không đặt được', 'ASM đã duyệt'],
            statusColors: {
                'Chờ xác nhận': 'blue',
                'Đã xác nhận': 'green',
                'Không đặt được': 'red',
                "ASM đã duyệt": 'pink',
            },
            bottomSheet: false,
            bottomSheetType: "",
            note: "",

            item: null,

            item1SelectObj: null,
            item1Select: [],
            items1:[],
            items2:[],
            itemsCxn: [],
            itemsDxn: [],
            itemsKdd: [],
            itemsKhd: [],
            itemsAsm: [],

            itemsCxnLength: 0,
            search:"",

            filterCode:"", 
            filterDepartment:"", 
            
            filterFromDate:moment().startOf('months').format('YYYY-MM-DD'),
            filterToDate: moment().endOf('months').format('YYYY-MM-DD'),
            filterStatus:"",
            filterChiNhanh:"",
            filterId:"",
            filterMaHang:"",
            filterNhanHang:"",
            filterNcc:"",

            currentItem: "",

            loadingDialog: false,
            globalSearch:"",
            globalSearchTemp:"",
            headers1: [
                {text: "Tình trạng", value: "TinhTrang", width:"200px",
                    filter: value => {
                        if (!this.filterStatus) return true
            
                        return value.toUpperCase().includes(this.filterStatus.toUpperCase())
                    }, sortable: false
                },
                {text: "ID", value: "ID", width:"70px" },
                {text: "Mã HH", value: "MaHang", width:"100px"},
                {text: "Ngành hàng", value: "NganhHang", width:"150px"},
                {text: "Cần ASM duyệt", value: "KhongHoaDon", width:"100px", sortable:false},
                {text: "Chi nhánh dự trù", value: "ChiNhanhDuTru", width:"100px",
                    filter: value => {
                        if (!this.filterChiNhanh) return true
            
                        return value.toUpperCase().includes(this.filterChiNhanh.toUpperCase())
                    },
                },
                {text: "Tên HH", value: "TenHang", width:"200px"},
                {text: "Số lượng dự trù", value: "SoLuongDuTru", width:"100px"},
                {text: "ĐVT", value: "DVT", width:"100px"},
                {text: "Nhãn hàng", value: "NhanHang",
                    filter: value => {
                        if (!this.filterNhanHang) return true
            
                        return value.toUpperCase().includes(this.filterNhanHang.toUpperCase())
                    },
                },
                // {text: "Nhà cung cấp", value: "NCC",
                //     filter: value => {
                //         if (!this.filterNcc) return true
            
                //         return value.toUpperCase().includes(this.filterNcc.toUpperCase())
                //     },
                //     width: 150
                // },
                {text: "Ghi chú", value: "GhiChu", width:"200px", filterable:false},
                {text: "Ngày dự trù", value: "NgayDuTruStr", width:"150px"},
                {text: "Tồn kho", value: "TonKhoHeThong", width:"100px"},
                {text: "Ghi chú X/N", value: "note", width:"250px"},
                {text: "Người tạo", value: "TenNguoiTao", width:"100px"},
                {text: "Người cập nhật", value: "TenNguoiCapNhat", width:"100px"},
                {text: "Người xác nhận", value: "TenNguoiXacNhan", width:"100px"},
                {text: "Asm Duyệt", value: "TenASMDuyet", width:"100px"},
                {text: "Ngày Asm X/N", value: "NgayASMDuyet", width:"100px", sortable: false},
                
                {text: "Loại hàng", value: "LoaiHang"},
                {text: "Trung bình bán", value: "TrungBinhBan"},
                {text: "Loại yêu cầu", value: "LoaiYeuCau"},
                {text: "Ngày giao khách", value: "NgayGiaoKhach"},
                {text: "Ghi chú dự trù được", value: "DuTruDuoc"},
                {text: "Ghi chú dự trù không được", value: "DuTruKhongDuoc"},
                // {text:"Thao tác", value: "actions", width:"100px"},
                // {text: "Ghi chú chấp nhận dự trù", value: "DuTruDuoc", width:"250px"},
                // {text: "Ghi chú từ chối dự trù", value: "DuTruKhongDuoc", width:"250px"},
                // {text: "NgayDuTru", value: "NgayDuTru", width:"100px"},
                // {text: "LinkAnh", value: "LinkAnh", width:"100px"},
            ],
            
            headers2: [
                {text: "Tình trạng", value: "TinhTrang", width:"220px",
                    filter: value => {
                        if (!this.filterStatus) return true
            
                        return value.toUpperCase().includes(this.filterStatus.toUpperCase())
                    }, sortable: false
                },
                {text: "ID", value: "ID", width:"70px", 
                    filter: value => {
                        if (!this.filterId) return true
            
                        return value.toString().includes(this.filterId.toUpperCase())
                    },
                },
                {text: "Mã HH", value: "MaHang", width:"100px",
                    filter: value => {
                        if (!this.filterMaHang) return true
            
                        return value.includes(this.filterMaHang.toUpperCase())
                    },
                },
                {text: "Ngành hàng", value: "NganhHang", width:"150px"},
                {text: "Cần ASM duyệt", value: "KhongHoaDon", width:"100px", sortable:false},
                {text: "Chi nhánh dự trù", value: "ChiNhanhDuTru", width:"100px",
                    filter: value => {
                        if (!this.filterChiNhanh) return true
            
                        return value.toUpperCase().includes(this.filterChiNhanh.toUpperCase())
                    },
                },
                {text: "Tên HH", value: "TenHang", width:"200px"},
                {text: "Số lượng dự trù", value: "SoLuongDuTru", width:"100px"},
                {text: "Ghi chú", value: "GhiChu", width:"200px", filterable:false},
                {text: "Ngày dự trù", value: "NgayDuTruStr", width:"150px"},
                {text: "ĐVT", value: "DVT", width:"100px", filterable:false},
                {text: "Tồn kho", value: "TonKhoHeThong", width:"100px"},
                {text: "Ghi chú X/N", value: "note", width:"250px"},
                {text: "Người tạo", value: "UserIDTao", width:"100px"},
                {text: "Người cập nhật", value: "UserIDCapNhat", width:"100px"},
                {text: "Người xác nhận", value: "UserIDXacNhan", width:"100px"},
                {text: "Asm Duyệt", value: "TenASMDuyet", width:"100px"},
                {text: "Ngày Asm X/N", value: "NgayASMDuyet", width:"100px", sortable: false},
                
                {text: "Loại hàng", value: "LoaiHang"},
                {text: "Trung bình bán", value: "TrungBinhBan"},
                {text: "Loại yêu cầu", value: "LoaiYeuCau"},
                {text: "Ngày giao khách", value: "NgayGiaoKhach"},
            ],

            questionTypes: [
                {text: "Tự luận", value: "TEXT"},
                {text: "Lựa chọn", value: "OPTION"},
                {text: "Nhiều lựa chọn", value: "MULTI_OPTION", disabled: true},
            ],
            question:{
                type: "OPTION",
                name:"",
            },
            questions:[],

            answerHeader:[
                {text:"Câu trả lời", value:"name"},
                {text:"Xóa", value:"actions", width: 150}

            ],
            answers: [],
            answer:"",

            dialogDelete: false,
            targetItem: null,
            dialog: false,
            questionDialog:false,
        }
    },
    methods: {
        setItemsCxnLength(pagination){
            this.itemsCxnLength = pagination.itemsLength
        },
        convertToExcelLabel(value) {
            if(Array.isArray(value)) {
                return value.map(x => {
                    return {
                        label: x.text,
                        field: x.value
                    }
                })
            }
        },
        convertImage(data) {
            const imgPrefix = 'https://storage.googleapis.com/tshrm-bucket/Uploads/DatHang/'
            if(data)
            {
                let arr = JSON.parse(data)
                if(arr.length>0){
                    let imgObj = arr[0]
                    return imgPrefix+imgObj['0']
                }
                return ''
            }
            return ''
        },
        fetchData() {
            return this.apiService.getTemplates().then(d => {
                this.items1 = d.data
            })
        },
        setSelect(e)
        {
            this.item= {code : e};
        },
        
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        publish(item) {
            this.apiService.publishTemplate(item).then(() => this.fetchData())
        },

        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            let url = `propertyRequests/${this.targetItem.code}`;
            return http.delete(url).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },

        //template
        openBottom(item, status, event) {
            this.item1SelectObj = item
            // if(status == 'Đã xác nhận' || status == 'Không đặt được')
            this.bottomSheet = true;
            if(status == "Đã xác nhận")
                this.bottomSheetType = "ACCEPT"
            if(status == "Không đặt được")
                this.bottomSheetType = "DENY"
            if(status == "Chờ xác nhận"){
                this.bottomSheetType = "RETURN"
            }
            console.log(item, event)
        },
        closeBottom() {
            this.bottomSheet = false
            this.bottomSheetType = ''
            this.note = ''
        },

        deleteTemplate() {
            return this.apiService.deleteTemplate(this.targetItem).then(()=> {
                this.targetItem=undefined
                this.dialogDelete = false
                this.fetchData()
            })
        },
        
        exportTemplate(item){
            try {
                this.loadingDialog = true
                return http.get("surveys/"+item.id+"/export", {
                    params: {
                        month: moment(this.month).format('YYYY-MM-DD')
                    },
                    responseType: 'blob',
                }).then(res => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `EXPORT_KHAO_SAT${moment(this.month, 'yyyy-MM-dd').format("MM-YYYY")}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                }).finally(()=> {
                    this.loadingDialog=false
                });
            } catch(e){
                alert(e)
            }
        },

        ///QUESTION
        closeQuestion() {
            this.questionDialog = false
            this.answers = []
            this.answer = ""
            this.question.name = ""
            this.question.type = "OPTION"
        },
        getQuestions() {
            try{
                return this.apiService.getQuestions(this.selectedTemplate).then(d => {
                    this.questions = d.data
                })
            }catch(e)
            {
                alert(e)
            }
        },
        saveQuestion(){
            let mappedAnswer = this.answers.map(x=>x.name)
            let postData = {
                name: this.question.name,
                type: this.question.type,
                answers: mappedAnswer
            }
            return this.apiService.postQuestion(this.selectedTemplate, postData).then(() => {
                this.closeQuestion()
                return this.getQuestions()
            })
        },
        deleteQuestion(id) {
            return this.apiService.deleteQuestion(this.selectedTemplate, id).then(() => {
                return this.getQuestions()
            })
        },
        changeQuestionOrder(id, mode) {
            return this.apiService.changeQuestionOrder(this.selectedTemplate, id, mode).then(() => {
                return this.getQuestions()
            })
        },

        /// ANSWER
        addAnswer() {
            this.answers.push({name: this.answer});
            this.answer="";
        },
        deleteAnswer(index) {
            // let index = this.answers.findIndex(obj => obj.name === name);
            if (index !== -1) {
            let removedObject = this.answers.splice(index, 1); // Remove the object from the array
            } else {
                alert('Object not found in the array.');
            }
        },
        
        getToken() {
            let userJson = localStorage.getItem("user")
            let user = JSON.parse(userJson)
            if(user){
                //NS0012 ma CM
                let employeeCode = 'NS0012'
                if(user.employeeCode != 'NV05039')
                    employeeCode = user.employeeCode

                return this.apiService.getToken(employeeCode).then(d => {
                    this.TOKEN = d.token
                    this.USER_ID = d.UserID
                    this.Sources = d.sourceCode
                    console.log(this.TOKEN, this.USER_ID, this.Sources)
                    // this.dathangForm.chinhanh_dutru = d.sourceCode
                })
            }
        },
        getPhieu() {
          return this.apiService.getPhieu(this.TOKEN, this.USER_ID, this.filterFromDate, this.filterToDate).then(d => {
            this.items1 = d.ds_dathang
            this.itemsCxn=this.items1.filter(x=>x.TinhTrang == 'Chờ xác nhận' || x.TinhTrang == 'ASM đã duyệt').sort((a, b) => a.TinhTrang - b.TinhTrang)
            this.itemsCxnLength = this.itemsCxn.length
            this.itemsDxn=this.items1.filter(x=> x.TinhTrang == 'Đã xác nhận')
            this.itemsKdd=this.items1.filter(x=> x.TinhTrang == 'Không đặt được')
            this.itemsKhd=this.items1.filter(x=> x.KhongHoaDon == 1)
            // this.itemsAsm=this.items1.filter(x=> x.TinhTrang == 'ASM đã duyệt')
          })
        },
        
        setStatusNote() {
            if(this.item1SelectObj){
                if(this.bottomSheetType == "ACCEPT")
                    return this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, this.note, 'ACCEPT').then(() => {
                            return this.apiService.setTinhTrang(this.item1SelectObj.ID, this.USER_ID,"Đã xác nhận").then(() =>{
                                this.closeBottom()
                                return this.getPhieu()
                            }
                            // this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, "", 'DENY').then(()=>{
                            //     this.closeBottom()
                            //     return this.getPhieu()
                            // })
                        )
                    })
                if(this.bottomSheetType == "DENY")
                    return this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, this.note, 'DENY').then(() => {
                        return this.apiService.setTinhTrang(this.item1SelectObj.ID, this.USER_ID,"Không đặt được").then(() => {
                            
                            this.closeBottom()
                            return this.getPhieu()
                        }
                        // this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, "", 'ACCEPT').then(()=>{
                        //     this.closeBottom()
                        //     return this.getPhieu()
                        //     })
                        )
                    })
                if(this.bottomSheetType == "RETURN"){
                    return this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, "", 'ACCEPT').then(() => {
                        return this.apiService.setTinhTrang(this.item1SelectObj.ID, this.USER_ID,"Chờ xác nhận").then(() =>
                            this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, "", 'DENY').then(()=>{
                                this.closeBottom()
                                this.getPhieu()
                            })
                        )
                    })
                }
            }
            return true
        },

        setKhongHoaDon(value, event) {
            this.apiService.setKhongHoaDon({
            "ID": value.ID,
            "user_capnhat": this.USER_ID,
            "khong_hoadon": event ? 1:0
            }).then(() => this.getPhieu())
        }
    },

    apiService: null,
    created() {
        this.apiService = new api()
    },
    mounted () {
      // this.fetchData()
      this.getToken().then(()=> this.getPhieu())
    }
}
</script>