<template>
<v-card>
    <create-dialog v-model="dialog" :item="item" @fetch="fetchData"></create-dialog>
    <gallery-dialog  v-model="dialogGallery" :item="galleryItem" @fetch="fetchData"></gallery-dialog>
    <v-card-title>
        <!-- Danh sách báo cáo hình ảnh -->
        <date-picker2 v-model="filters.date" outlined :hideDetails="true" dense label="Ngày xem báo cáo"></date-picker2>
        <!-- <export-prompt url="insurances" v-model="exportPrompt" fileName="BAO_HIEM"></export-prompt> -->
        <v-spacer></v-spacer>
        <!-- <create-button @click="dialog=true"></create-button> -->
        <refresh-button @click="fetchData(true)"></refresh-button>
        <v-btn @click="getLinkDownload" dark color="teal darken-4" small>Excel</v-btn>
        <!-- <excel-button @click="exportPrompt=true"/> -->
    </v-card-title>
    <v-card-title>
        <v-row>
            <v-col cols="12" md="3">
                <v-text-field label="Tìm theo tên" v-model="clientFilters.name" placeholder="Nhập để tìm" outlined hide-details dense>
                </v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-autocomplete label="Tìm theo phòng ban"  v-model="clientFilters.departmentCode" clearable placeholder="Nhập để tìm" outlined hide-details dense 
                :items="departments" item-text="name" item-value="name"
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
                <v-autocomplete label="Tìm theo danh mục"  v-model="clientFilters.imageReportCategoryCode" clearable placeholder="Nhập để tìm" outlined hide-details dense 
                :items="categories"
                ></v-autocomplete>
            </v-col>
        </v-row>
    </v-card-title>
    <v-data-table :headers="headers" :items="items" height="600px" :page="page">
        
        <template v-slot:[`item.actions`]="{item}">
            <edit-button @click="edit(item)"></edit-button>
            <delete-button @click="remove(item)"></delete-button>
            <detail-button @click="gallery(item)" icon="mdi-image-multiple"></detail-button>
            <v-btn x-small @click="downloadZip(item)" plain>zip</v-btn>
        </template>

        <!-- <template v-slot:[`header.name`]="{ header }">
            <div class="d-block">{{header.text}}</div>
        </template> -->

        <!-- <template v-slot:[`header.department`]="{ header }">
            <div class="d-block">{{header.text}}</div>
        </template> -->

        <template v-slot:[`header.description`]="{ header }">
            <div class="d-block">{{header.text}}</div>
            <v-text-field @click="blockClick" v-model="clientFilters.description" placeholder="Nhập để tìm" outlined hide-details dense full-width class=" header-filter caption"
            ></v-text-field>
        </template>

        
        <template v-slot:[`item.images`]="{ item }">
            <div  v-if="item.images" >
                <v-img  v-for="(image, key) in item.images.split(';')" :key="key" :src="image" width="64px" height="64px" @click="openURL(image)" 
                contain class="d-inline-block" lazy-src="https://via.placeholder.com/32x32">
                    
                </v-img>
            </div>
        </template>
        
    </v-data-table>
    
    <!-- galery dialog -->
    <v-dialog v-model="imageDialog" width="500px">
        <v-card>
            <v-card-title>Hình ảnh</v-card-title>
            <img :src="imageUrl" style="object-fit: contain;" width="500px"/>
        </v-card>
    </v-dialog>

    <!-- ************** DELETE CONFIRMATION *************** -->
    <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
            <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
            <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
            <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-card>
    
</template>
<script>
import http from '@/components/services/http-common';
import DataFilter from '@/components/DataFilter';
import * as moment from "moment/moment";
import CreateDialog from './Create.vue';
import GalleryDialog from './Gallery.vue';
import JSZip from 'jszip';
import { saveAs } from 'file-saver'; 
export default {
    name: "ImageReportIndex",
    
    components: {
        'data-filter': DataFilter,
        'create-dialog': CreateDialog,
        'gallery-dialog': GalleryDialog,
    },
    props: ["breadcrumbs", "icon"],
    watch: {
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        dialogGallery(val) {
            if(!this.dialog)
                this.item=null;
        },
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        

    },
    computed: {
        params: function() {
            return {...this.tableParams, ...this.filters}
        },
        
    },
    data () {
        return {
            ROOT_URL: "imageReports/",
            exportPrompt: false,

            //filter
            page:1,
            filters:{
                date:[moment().startOf("year").format("YYYY-MM-DD"), moment().endOf("year").format("YYYY-MM-DD")],
                isAdmin: 1
            },

            clientFilters: {
                name: "",
                departmentCode: "",
                imageReportCategoryCode:"",
                description:""
            },

            departmentSearch:null,
            departments: [],
            categories: [],
            shifts:[],
            rowCount: 0,
            
            // VIEW DATA
            filterOpen: 1,

            //DATA TABLE
            item:{},
            galleryItem:{},
            totalItem: 0,
            items: [],
            search:"",
            options: {},
            isLoading: true,

            dialog: false, item: null,
            dialogDelete: false,
            dialogGallery: false,
            targetItem: undefined,
            imageUrl: "",
            imageDialog:false,

            headers: [
                { text: 'Thao tác', value: 'actions', width: '9rem'},
                { text: 'Mã', value: 'code', width: '7rem'},
                { text: 'Danh mục', value: 'imageReportCategory', width: '7rem', 
                    filter: value => {
                    if (!this.clientFilters.imageReportCategoryCode) return true

                    return value.toLowerCase()=== (this.clientFilters.imageReportCategoryCode.toLowerCase())
                    },
                },
                { text: 'Tên', value: 'name', width: '7rem', 
                    filter: value => {
                    if (!this.clientFilters.name) return true

                    return value.toLowerCase().includes(this.clientFilters.name.toLowerCase())
                    },
                },
                { text: 'Hình ảnh', value: 'images', width: '450px'},
                { text: 'Phòng ban', value: 'department', width: '9rem', 
                    filter: value => {
                    if (!this.clientFilters.departmentCode) return true
                    return value.toLowerCase().includes(this.clientFilters.departmentCode.toLowerCase())
                    },
                },
                { text: 'Mô tả', value: 'description', width: 250, 
                    filter: value => {
                    if (!this.clientFilters.description) return true
                    return value.toLowerCase().includes(this.clientFilters.description.toLowerCase())
                    },
                },
                { text: 'Tạo bởi', value: 'createdBy', width: 200},
                { text: 'Ngày tạo', value: 'createdAt', width: 250},
            ],

        }
    },
    methods: {
        getLinkDownload() {
            return http.get("imageReports/export", {
                params: this.filters
            }).then(res => {
                console.log(res)
                window.open(res.data.data, '_blank').focus();
            })
        },
        downloadZip(row) {
            var fileUrls = row.images.split(';');

            const zip = new JSZip();
            try {
                let i = 1;
                const fetchPromises = fileUrls.map(url => {
                    // Fetch the file
                    return fetch(url).then(response => {
                        if (!response.ok) {
                            throw new Error(`Failed to fetch ${url}`);
                        }
                        return response.blob()
                    } ).then(blob => {
                        const fileName = url.split('/').pop(); // Get the file name from the URL
                        console.log('b' + i,  blob)
                        i++
                        // Add the file to the zip
                        zip.file(fileName, blob);
                        return Promise.resolve();
                    });
                })

                Promise.all(fetchPromises)
                .then(() => {
                    // Generate the zip file after all files are added
                    return zip.generateAsync({ type: 'blob' });
                })
                .then(zipBlob => {
                    // Trigger a download of the zip file
                    console.log('eBlob', zipBlob)
                    saveAs(zipBlob, 'files.zip');
                })
                .catch(error => {
                    console.error('Error downloading and zipping files:', error);
                });

            }catch(e) {
                alert("error")
            }

        },
        openURL(url)
        {
            this.imageDialog = true;
            this.imageUrl = url;
            // var image = new Image();
            // image.src = url;
            // image.height =800;

            // var w = window.open("");
            // w.document.write(image.outerHTML);
            // window.open(url, "_blank");
        },
        copyURL(Url) {
            // var Url = this.$refs.mylink;
            Url.innerHTML = window.location.href;
            console.log(Url.innerHTML)
            Url.select();
            document.execCommand("copy");
        }, 
        blockClick(event)
        {
            event.stopPropagation()
        },
        formatTime(value) {
            return moment(value).format("DD/MM/YYYY HH:mm:ss");
        },
        fetchDepartment() {
            return http.get("departments/fetch", {
                params: {
                    search: this.departmentSearch
                }
            }).then(res => {
                this.departments = res.data;
            })
        },
        fetchCategory() {
            return http.get("imageReportCategories/fetch", {
                params:{
                    showOnly: 0
                }
            }).then(d => {
                this.categories = d.data.map(x => {return {
                    text: x.name,
                    value: x.name,
                }})
            })
        },
        fetchShift() {
            return http.get("shifts").then(res => {
                this.shifts = res.data;
            })
        },
        fetchData (refresh=false) {
            if(refresh)
                this.page = 1;
            return http.get(this.ROOT_URL, {
                params: this.filters
            })
            .then(res => {
                this.isLoading = false;
                this.items = res.data.items;
            })
            .catch(err => {

            })
        },
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        
        gallery(item){
            this.galleryItem = item;
            this.dialogGallery = true;
        },
        
        remove(item){
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            return http.delete(this.ROOT_URL+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            })
        },
    },
    created () {
        this.fetchCategory()
        this.fetchData()
        this.fetchShift()
        this.fetchDepartment()
    },
    mounted() {
        var token = localStorage.getItem('token')

        return http.get("account/jwt/get-id").then(r=>r.data).then(d => {
            window.open(`https://feedback.tshrm.trungsonpharma.com/auth/sign-in?token=${d.data}&redirect=mkt`, '_blank').focus();
        })
        // alert("redirected to v2")
    }
    
}
</script>


<style scoped>
    ::v-deep table > thead > tr > th> .v-input.header-filter  {
        max-width:calc(100% - 20px);
        margin-bottom: 12px;
        display: inline-block !important;
        
    }
</style>