<template>
    <div class="enrollment">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" @createForm="dialog=true"></myBreadCrumb> -->
        <create-dialog v-model="dialog" :item="item" @fetch="fetchData"></create-dialog>
        <v-card>
            <v-card-title>
                <!-- <month-picker v-model="filters.month" label="Chọn tháng" width="7rem" dense outlined ></month-picker>  -->
                
                <!-- <v-select label="Trạng thái" class="mr-2" dense v-model="status" style="max-width:150px" clearable :items="statuses" outlined hide-details></v-select>
                <v-autocomplete label="Nơi làm việc" placeholder="Nhập" class="mr-2" dense v-model="wishCityCode"  style="max-width:150px" clearable :items="cities" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                <v-autocomplete label="Công việc" placeholder="Nhập" style="max-width:200px" class="mr-2" dense v-model="jobCode" clearable :items="jobs" item-value="code" item-text="name" outlined hide-details></v-autocomplete>

                <v-btn color="teal" dark @click="fetchData"><v-icon small left>mdi-filter</v-icon> Lọc</v-btn>
                <v-spacer></v-spacer>
                <v-btn class="mx-1" icon><v-icon @click="fetchData">mdi-refresh</v-icon></v-btn> -->
                
                <!-- <v-btn color="teal" dark @click="fetchData"><v-icon small left>mdi-filter</v-icon> Lọc</v-btn> -->
                Danh sách ứng viên
                <v-spacer></v-spacer>
                <v-btn class="mx-1" icon><v-icon @click="fetchData">mdi-refresh</v-icon></v-btn>
                <excel :cities="cities" :jobs="jobs" class="mx-1 d-flex"></excel>
            </v-card-title>
            <v-row class="px-4">
                <v-col cols="12" md="2">
                    <v-text-field type="date" v-model="fromDate" label="Từ ngày" dense outlined class="mr-2" hide-details></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                    <v-text-field type="date" v-model="toDate" label="Đến ngày" dense outlined class="mr-2" hide-details></v-text-field>
                </v-col>
                
                <v-col cols="12" md="2">
                    <v-select label="Trạng thái" class="mr-2" dense v-model="status" clearable :items="statuses" outlined hide-details></v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-autocomplete label="Nơi làm việc" placeholder="Nhập" class="mr-2" dense v-model="wishCityCode"  clearable :items="cities" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                </v-col>
                <v-col cols="12" md="2">
                    <v-autocomplete label="Công việc" placeholder="Nhập"  class="mr-2" dense v-model="jobCode" clearable :items="jobs" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                </v-col>
                <v-col cols="12">
                    <span>
                        <ul>
                            <li>
                                <small><span class="red--text">Nguyễn Văn A</span>: Nhân viên đã có trong hệ thống, nộp hồ sơ lại.</small> 
                            </li>
                            <li>
                                <small> 
                                    <v-badge class="mr-3 mt-3"
                                        color="red" :content="2"
                                        ><span class="">Nguyễn Văn A</span>
                                    </v-badge>: Số lần ứng viên đã gởi hồ sơ và hệ thống.
                                </small> 
                            </li>
                        </ul>
                    </span>
                </v-col>
            </v-row>
            <v-row class="px-4 mb-4">

                <v-col cols="12" class="d-flex justify-end">
                    <v-btn text color="green darken-4" style="max-width: 200px;text-decoration: underline;" dark @click="fetchData" > Áp dụng bộ lọc </v-btn>
                </v-col>
            </v-row>
            <!-- <v-data-table
                :mobile-breakpoint="0"
                height="calc(100vh - 300px)" fixed-header
                :headers="headers"
                :items="items"
                :page.sync="tableParams.page"
                :sort-by.sync="tableParams.sortBy"
                :sort-desc.sync="tableParams.sortDesc"
                :server-items-length="rowCount"
                :loading="isLoading"
                loading-text="Đang tải dữ liệu..."
                :items-per-page.sync="tableParams.pageSize"
                :footer-props="{
                    itemsPerPageOptions:[
                    15, 50, 100, 200
                    ]
                }"
            >
                <template v-slot:[`body.prepend`]="" >
                    <tr class="no-hover">
                        <td></td>
                        <td>
                            <v-text-field placeholder="Nhập" dense v-model="filters.code" outlined hide-details></v-text-field>
                        </td>
                        <td></td>
                        <td>
                            <date-picker2 v-model="filters.createdAt" :range="true" label="" :dense="true" :hideDetails="true" outlined ></date-picker2>
                        </td>
                        <td>
                            <v-text-field placeholder="Nhập" dense v-model="filters.name" clearable outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <date-picker2 v-model="filters.bdFilter" :range="true" label="" :dense="true" :hideDetails="true" outlined hide-details :width="200"></date-picker2>
                        </td>
                        <td>
                            <v-autocomplete placeholder="Nhập" dense v-model="filters.status" clearable :items="statuses" outlined hide-details></v-autocomplete>
                        </td>
                        <td>
                            <date-picker2 v-model="filters.interviewAt" :range="false" label="" :dense="true" :hideDetails="true" outlined hide-details></date-picker2>
                        </td>
                        <td>
                            <v-autocomplete placeholder="Nhập" dense v-model="filters.jobCode" clearable
                            :items="jobs" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            <v-autocomplete placeholder="Nhập" dense v-model="filters.cityCode" clearable
                            :items="cities" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            <v-text-field placeholder="Nhập" dense v-model="filters.phone" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-text-field placeholder="Nhập" dense v-model="filters.mail" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-autocomplete placeholder="Nhập" dense v-model="filters.city1Code" clearable
                            :items="cities" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                        </td>
                        <td>
                            <v-autocomplete placeholder="Nhập" dense v-model="filters.city2Code" clearable
                            :items="cities" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                        </td>
                        <td>
                            <v-autocomplete placeholder="Nhập" dense v-model="filters.unisCode" clearable
                            :items="unis" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                        </td>
                        <td>
                            <v-autocomplete placeholder="Nhập" dense v-model="filters.eduLevelCode" clearable
                            :items="eduLvls" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                        </td>
                        <td>
                            <v-autocomplete placeholder="Nhập" dense v-model="filters.majorCode" clearable
                            :items="majors" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                        </td>
                    </tr>
                </template>
                
                <template v-slot:[`item.interviewAt`]="{ item }" >
                    {{ formatDate(item.interviewAt)=="01/01/0001" ? "": formatDate(item.interviewAt) }}
                </template>
                <template v-slot:[`item.name`]="{ item }" >
                    <v-badge v-if="item.duplicateNum>0"
                    color="red" :content="item.duplicateNum"
                    >{{item.name}}
                    </v-badge>
                    <p v-else>{{item.name}}</p>
                </template>
                <template v-slot:[`item.actions`]="{item}" >
                    <edit-button @click="edit(item)" label="Chi tiết"></edit-button>
                    <delete-button @click="remove(item)" label="Xóa"></delete-button>
                </template>
                <template v-slot:[`item.createdAt`]="{ item }" >
                    {{ formatDate(item.createdAt) }}
                </template>
                <template v-slot:[`item.time`]="{ item }" >
                    {{ formatDate(item.time) }}
                </template>
                <template v-slot:[`item.status`]="{ item }" >
                    <v-chip :color="formatStatusColor(item.status)" label small outlined>
                        <strong> {{formatStatus(item.status)}}</strong>
                    </v-chip>
                </template>
                
                <template v-slot:[`item.inSystem`]="{ item }" >
                    <v-chip v-if="item.inSystem == 1" color="red" label small outlined>
                        <strong> {{item.inSystem == 1? 'Tồn tại trong hệ thống': ''}}</strong>
                    </v-chip>
                </template>
                <template v-slot:[`item.avatarUrl`]="{ item }">
                    <v-avatar class="my-1" size="54">
                        <v-img
                            lazy-src="https://banner2.cleanpng.com/20180625/req/kisspng-computer-icons-avatar-business-computer-software-user-avatar-5b3097fcae25c3.3909949015299112927133.jpg"
                            :src="item.avatarUrl"
                            alt="Avatar"
                            v-if="item.avatarUrl" ></v-img>
                    </v-avatar>
                </template>
            </v-data-table> -->
        </v-card>
        <v-card>
                <v-col cols="12" class="d-flex justify-end">
                    <v-text-field style="max-width: 200px;" outlined dense placeholder="Gõ enter để tìm kiếm" hint="Gõ enter để tìm kiếm" v-model="searchTemp" @keydown.enter.prevent="search=searchTemp" hide-details="auto"></v-text-field>
                </v-col>
            <v-data-table
                :headers="headers2" :items="items"
                :expanded.sync="expanded" item-key="code"
                show-expand class="elevation-1" :loading="isLoading"
                :search="search" height="500" fixed-header
            >
                <template v-slot:[`item.avatarUrl`]="{ item }">
                    <v-avatar class="my-1" size="54">
                        <v-img
                            lazy-src="https://banner2.cleanpng.com/20180625/req/kisspng-computer-icons-avatar-business-computer-software-user-avatar-5b3097fcae25c3.3909949015299112927133.jpg"
                            :src="item.avatarUrl"
                            alt="Avatar"
                            v-if="item.avatarUrl" ></v-img>
                    </v-avatar>
                </template>
                <template v-slot:[`item.name`]="{ item }" >
                    <v-badge v-if="item.duplicateNum>0"
                    color="red" :content="item.duplicateNum"
                    > <span :style="`${item.inSystem==1?'color:red':''}`">{{item.name}}</span> 
                    </v-badge>
                    <p v-else :style="`${item.inSystem==1?'color:red':''}`">{{item.name}}</p>
                </template>
                <template v-slot:[`item.status`]="{ item }" >
                    <v-chip :color="formatStatusColor(item.status)" label small outlined>
                        <strong> {{formatStatus(item.status)}}</strong>
                    </v-chip>
                </template>
                
                <template v-slot:[`item.pharmaCert`]="{ item }" >
                    {{formatPharmaCert(item.pharmaCert)}}
                </template>
                
                <template v-slot:[`item.cityName`]="{ item }" >
                    {{`${item.cityName}, ${item.districtName}, ${item.wardName}, ${item.address}`}}
                </template>
                <template v-slot:[`item.dCityName`]="{ item }" >
                    {{`${item.dCityName}, ${item.dDistrictName}, ${item.dWardName}, ${item.dAddress}`}}
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <p><span class="font-weight-bold">Địa chỉ thường trú: </span> {{`${item.cityName}, ${item.districtName}, ${item.wardName}, ${item.address}`}}</p>
                        <p v-if="item.dCityName!=''"><span class="font-weight-bold">Địa chỉ tạm trú: </span> {{`${item.dCityName}, ${item.dDistrictName}, ${item.dWardName}, ${item.dAddress}`}}</p>
                        <p><span class="font-weight-bold">Trình độ h.vấn: </span> {{`${item.educationLevelName}`}}</p>
                        <p><span class="font-weight-bold">Chuyên ngành: </span> {{`${item.educationMajorName}`}}</p>
                    
                        <p><span class="font-weight-bold">Chiều cao: </span> {{`${item.height}`}}</p>
                        <p><span class="font-weight-bold">Cân nặng: </span> {{`${item.weight}`}}</p>
                        <p><span class="font-weight-bold">Nơi làm việc mong muốn 1: </span> {{`${item.wishCity1Name}`}}</p>
                        <p><span class="font-weight-bold">Nơi làm việc mong muốn 2: </span> {{`${item.wishCity2Name}`}}</p>
                        <p v-if="item.inSystem"><span class="font-weight-bold red">Trong hệ thống </span></p>
                    </td>
                </template>
                
                <template v-slot:[`item.actions`]="{item}" >
                    <edit-button @click="edit(item)" label="Chi tiết"></edit-button>
                    <v-btn x-small color="info" icon @click="dialogPdf=true, pdfUrl = item.cvFileUrl"><v-icon>mdi-file</v-icon></v-btn>
                </template>

                
                <template v-slot:[`item.deleteAction`]="{item}" >
                    <delete-button @click="remove(item)" label="Xóa"></delete-button> Mã: {{ item.code }}
                </template>
            </v-data-table>
        </v-card>

        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                    <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <v-dialog v-model="dialogPdf" max-width="800px">
            <v-card>
                <v-card-title class="headline">Xem CV
                    <v-btn small class="ml-auto" @click="$refs.pdfPreview.download()" dark color="green">Tải xuống</v-btn>
                </v-card-title>
                <v-card-text class="text--primary" v-if="!!pdfUrl  && pdfUrl.includes('pdf')">
                    <vue-pdf-embed :style="'zoom:'+ zoom" ref="pdfPreview" :source="pdfUrl+'?ticket=' + generateRandom10DigitNumber()" :width="800"/>
                </v-card-text>
                <v-card-text class="text--primary" v-else>
                    <h3>Nhân viên đã không gởi file CV</h3>
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn  text @click="dialogPdf=false">Đóng</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import http from '@/components/services/http-common';
import myBreadCrumb from '@/components/BreadCrumb';
import DataFilter from '@/components/DataFilter';
import CreateDialog from './Create.vue';
import * as moment from "moment/moment";
import Excel from "./Excel.vue";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
    name: "CandidateIndex",
    components: {
        VuePdfEmbed,
        myBreadCrumb,
        'data-filter': DataFilter,
        'create-dialog': CreateDialog,
        'excel': Excel,

    },
    props: ["breadcrumbs", "icon"],
    watch: {
        dialog(val) {
            this.fetchCity();
            if(!this.dialog)
                this.item=null;
        },
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                if(!val.createdAt){
                    val.createdAt = [moment().startOf('year').format("YYYY-MM-DD"), moment().endOf('month').format("YYYY-MM-DD")]
                }
                this.fetchData();
            },
            deep: true,
        },

    },
    computed: {
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },
    data () {
        return {
            ROOT_URL: "candidates",
            endpoint: process.env.VUE_APP_BASE_URL,
            
            //filter
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
                pageSize:15
            },
            statuses: [
                {text: "Tất cả", value: ""},
                {text: "Mới", value: "NEW"},
                {text: "Chờ", value: "PEND"},
                {text: "Đạt", value: "PASS"},
                {text: "Loại", value: "FAIL"},
                {text: "Không liên hệ", value: "BLACK"},
            ],
            jobs:[],
            cities:[],
            unis:[],
            eduLvls:[],
            majors:[],
            filters:{
                code:null,
                createdAt:[moment().startOf('year').format("YYYY-MM-DD"), moment().endOf('month').format("YYYY-MM-DD")],
                status:null,
                name:null,
                bdFilter:[],
                jobCode:null,
                cityCode:null,
                city1Code:null,
                city2Code:null,
                unisCode:null,
                eduLevelCode:null,
                majorCode:null,
                phone:null,
                mail:null,
                month: moment().format("YYYY-MM"),
            },
            rowCount: 0,
            
            recruitChannels:[
                {text: "Mạng xã hội", value: "MXH"},
                {text: "Tờ rơi", value: "TR"},
                {text: "Được giới thiệu", value: "N"},
                {text: "Trang tuyển dụng", value: "TTD"},
            ],

            filter: {
                menu: false,
                search: "",
                searchAttr: "name",
                searchAttrs: undefined,
                FilterAttrs: [],
                FilterOn:["DepartmentTypeCode"],
                
                from: undefined,
                to: undefined,
                fromOns: [
                ],
                fromOn: "",
            },
            
            // VIEW DATA
            filterOpen: 1,

            //DATA TABLE
            totalItem: 0,
            items: [],
            search:"",
            searchTemp: "",
            options: {},
            isLoading: true,
            quickItem: {name: undefined, status:undefined },

            dialog: false, item: null,
            dialogDelete: false,
            dialogPdf: false,
            pdfUrl: "",
            targetItem: undefined,

            headers: [
                { text: 'Thao tác', value: 'actions',width: 120},
                { text: 'Mã', align: 'start', value: 'code', width: 100},
                // { text: "Avatar", value:"avatarUrl", align: 'center', sortable: false},
                { text: 'Ứng viên', align: 'start', value: 'name', width: 200},
                { text: 'Ngày sinh', align: 'start', value: 'birthday', width: 200},
                { text: 'Trạng thái', align: 'start', value: 'status', width: '9rem'},
                { text: 'Ngày phỏng vấn', align: 'start', value: 'interviewAt', width:'12rem'},
                { text: 'Công việc ứng tuyển', align: 'start', value: 'job', width: 200},
                { text: 'Sô CMND', align: 'start', value: 'idNumber', width: 100},
                { text: 'Nơi cấp', align: 'start', value: 'idPlace', width: 100},
                { text: 'Ngày cấp', align: 'start', value: 'idDate', width: 100},
                { text: 'Thành phố', align: 'start', value: 'city', width: 250},
                { text: 'Quận', align: 'start', value: 'district', width: 250},
                { text: 'Phường', align: 'start', value: 'ward', width: 250},
                { text: 'Địa chỉ', align: 'start', value: 'address', width: 250},
                { text: 'Phone', value: 'phone', width: 200},
                { text: 'Mail', value: 'mail', width: 200 },
                { text: 'Nơi làm việc mong muốn 1', value: 'city1', width: 200 },
                { text: 'Nơi làm việc mong muốn 2', value: 'city2', width: 200 },
                { text: 'Ngày gửi', align: 'start', value: 'createdAt', width:'12rem'},

                { text: 'Trường', value: 'university', width: 200 },
                { text: 'Học vấn', value: 'educationLevel', width: 200 },
                { text: 'Chuyên ngành', value: 'educationMajor', width: 200 },
                { text: 'Đã tồn tại trong hệ thống', value: 'inSystem', width: 200 },
            ],

            
            expanded: [],

            fromDate: moment().startOf('year').format("YYYY-MM-DD"),
            toDate:  moment().endOf('month').format("YYYY-MM-DD"),
            status:"",
            wishCityCode: "",
            jobCode:"",
            headers2: [
                { text: '', value: 'data-table-expand', filterable: false },
                { text: 'Thao tác', value: 'actions',width: '9rem', filterable: false, sortable: false},
                { text: 'Mã', value: 'code', width: 50},
                { text: 'Tên', value: 'name', width: 150},
                { text: 'Giới tính', value: 'gender', width: 100},
                { text: 'D/c Thường trú', value: 'cityName', width: 150, filterable: false },
                { text: 'Cơ sở đào tạo', value: 'universityName', width: 150, filterable: false },
                { text: "Công việc", value:"jobName", width:100},
                // { text: '', value: 'avatarUrl', width:100, filterable: false},
                // { text: 'Nguồn', value: 'recruitChannel', width:150},
                { text: 'Trạng thái', value: 'status', width: 50, filterable: false},
                { text: 'Ngày gởi', value: 'sentDate', width:150},
                { text: 'Số căn cước công dân', value: 'idNumber', width: 100},
                { text: 'Ngày cấp', value: 'idDate', width: 100, filterable: false},
                { text: 'Nơi cấp', value: 'idPlace', width: 100, filterable: false},
                { text: 'Ngày sinh', value: 'birthday', width: 100 },
                { text: 'SDT', value: 'phone', width: 100 },
                { text: 'E-mail', value: 'mail', width: 50 },
                { text: 'Nơi làm việc mong muốn 1', value: 'wishCity1Name', width: 150 },
                { text: 'Nơi làm việc mong muốn 2', value: 'wishCity2Name', width: 150 },
                { text: 'Xl Tốt nghiệp', value: 'graduateType', width: 150, filterable: false },
                { text: 'CCHN', value: 'pharmaCert', width: 150, filterable: false },
                { text: 'Xóa hồ sơ', value: 'deleteAction',width: '9rem', filterable: false, sortable: false},

            ],
        }
    },
    methods: {
        generateRandom10DigitNumber() {
            const min = 1000000000; // Smallest 10-digit number (10 zeros)
            const max = 9999999999; // Largest 10-digit number (nine nines)

            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        formatPharmaCert(value) {
            const pharmaCerts = {
                "KHONG": "Không có",
                "CD": "CCHN Cao đẳng",
                "DH": "CCHN Đại học",
            }
            return pharmaCerts[value]
        },
        formatStatus(value) {
            switch(value){
                case "NEW":
                    return "Mới"
                case "PEND":
                    return "Chờ"
                case "PASS":
                    return "Đạt"
                case "FAIL":
                    return "Loại"
                case "ACCEPT":
                    return "Nhận việc"
                case "NMEET":
                    return "Không phỏng vấn"
                case "BLACK":
                    return "Không liên hệ"
            }
        },
        formatStatusColor(value) {
            switch(value){
                case "NEW":
                    return "light"
                case "PEND":
                    return "orange"
                case "PASS":
                    return "success"
                case "FAIL":
                    return "error"
                case "ACCEPT":
                    return "teal"
            }
        },
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        fetchJob() {
            return http.get("jobs").then(res=> {
                this.jobs = res.data;
            })
        },
        fetchCity() {
            return http.get("cities/fetch").then(res=> {
                this.cities = res.data.data;
                console.log(this.cities)
            })
        },
        fetchData () {
            // return http.get(this.ROOT_URL, {
            //     params: this.params
            // })
            // .then(res => {
            //     this.isLoading = false;
            //     this.items = res.data.results;
            //     this.rowCount = res.data.rowCount;
            // })
            this.isLoading = true;
            return http.get(this.ROOT_URL+"/v2", {
                params: {
                    fromDate: this.fromDate,
                    toDate: this.toDate,
                    status: this.status,
                    wishCityCode: this.wishCityCode,
                    jobCode: this.jobCode
                }
            })
            .then(res => {
                console.log(res.data);
                this.isLoading = false;
                this.items = res.data.data;
                // this.rowCount = res.data.rowCount;
            })
        },
        fetchUni(){
            return http.get("Universities?dropDown=1").then(res => {
                this.unis = res.data
            })
        },
        fetchMajor(){
            return http.get("educationMajors?dropDown=1").then(res => {
                this.majors = res.data
            })
        },
        fetcheducationLevels(){
            return http.get("educationLevels?dropDown=1").then(res => {
                this.eduLvls = res.data
            })
        },
        edit(event ){
            this.item = event;
            this.dialog = true;
        },

        save(item){
            return http.post("candidates/quick-action/"+item.code, item).then(res=>{
                console.log(res.data);
            })
        },
        
        remove(item){
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            return http.delete("candidates/"+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            })
        },
        timeFormat(val){
            return moment(val).format("YYYY-MM-DD")
        }
    },
    created() {
        this.fetchJob();
        this.fetchCity();
        this.fetchUni();
        this.fetchMajor();
        this.fetcheducationLevels();
        this.fetchData();
        this.$emit("setUrl",  this.breadcrumbs);
    },
    destroyed() {
        this.$emit("setUrl", []);
    }
}
</script>
<style scoped>
.table-cursor tbody tr:hover {
cursor: pointer;    
}
::v-deep .no-hover:hover{
}

::v-deep table > tbody > tr.no-hover:hover
{
    background-color: white !important;
}

/* ::v-deep table > tbody > tr:nth-child(1)>td {
    padding-right: 1px !important;
    padding-left: 1px !important;
} */
</style>