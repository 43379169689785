<template>
    <v-dialog v-model="dialog" max-width="600px" persistent fullscreen scrollable
        transition="dialog-bottom-transition">
        <v-card class="d-flex flex-column">
            <v-card-title class="d-flex">
                {{isEdit ? "Thay đổi" :"Thêm"}} bài viết
                <v-spacer></v-spacer> <v-icon small @click="dialog=false"> mdi-close</v-icon>
            </v-card-title>
            <!-- <v-toolbar flat>
                <v-toolbar-title>
                </v-toolbar-title>
            </v-toolbar> -->
            <!-- <v-img :src="'data:image/png;base64, '+form.base64Body" ></v-img> -->
            <v-card-text class="pt-3 text-primary">
                {{form.templateId}}
                <v-form  v-model="isValid" ref="form" @submit.prevent="saveData" id="form">
                <v-row class="">
                    <!-- basic info -->
                    <v-col cols="12" md="6">
                        <v-row>
                            <v-col cols="6">
                                <v-img class="mx-auto" style="border-width: 2px; border-style: solid; border-color: black;" alt="hinh anh"
                                :src="form.avatar"
                                aspect-ratio="1.7"
                                ></v-img>
                            </v-col>
                            <v-col cols="6">
                                <v-file-input hide-details="auto" outlined v-model="form.avartarFormFile" label="Avatar" 
                                persistent-placeholder placeholder="Upload hình ảnh tại đây">
                                    <template v-slot:prepend-inner>
                                    </template>
                                </v-file-input>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field  hide-details="auto" outlined v-model="form.title" label="Tiêu đề"> </v-text-field>
                            </v-col>
                            <v-subheader style="width: 100%;">
                                Links 
                            </v-subheader>
                            <v-col cols="6">
                                <v-text-field v-model="linkName" outlined hide-details placeholder="Tên hiển thị link">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="link" outlined hide-details placeholder="Nhận link của bạn">
                                    <template v-slot:append>
                                        <v-btn small color="success" @click="addLink">Thêm link</v-btn>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea  hide-details="auto" outlined v-model="form.brief" label="Tóm tắt"> </v-textarea>
                            </v-col>
                            <v-col  cols="12">
                                <v-select  hide-details="auto" outlined v-model="form.contentCategoryCode" :items="categories" item-text="title" item-value="code" label="Danh mục"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <editor v-model="form.body"/>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-row>
                            <v-col cols="12"> 
                                <v-autocomplete hide-details="auto" outlined v-model="form.departmentTypeCode" :items="departmentTypes" clearable :disabled="form.allDepartment"
                                    item-text="name" item-value="code" label="Khối" persistent-placeholder>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete hide-details="auto" outlined v-model="departmentsPopUpList" :items="departments" multiple :disabled="form.allDepartment || form.departmentTypeCode"
                                    item-text="name" item-value="code" label="Phòng ban hiển thị thông báo" persistent-placeholder>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete  hide-details="auto" outlined :disabled="form.allDepartment" 
                                :items="employees" item-value="code" item-text="name" v-model="employeesPopUpList" :filter="customFilter" multiple
                                label="Chọn nhân viên" persistent-placeholder clearable> 
                                    <template v-slot:[`item`]="{item}">
                                        <v-list-item-content>
                                            <v-list-item-title v-html="item.name"></v-list-item-title>
                                            <v-list-item-subtitle v-html="'Mã NS: ' + item.code"></v-list-item-subtitle>
                                            <v-list-item-subtitle v-html="'Phòng: ' + item.department"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </v-autocomplete>

                            </v-col>
                            <v-col cols="12">
                                <v-textarea  hide-details="auto" :disabled="form.allDepartment" label="Chọn nhân viên" rows="3" outlined v-model="employeeCodeMass" 
                                persistent-placeholder placeholder="Dán mã nhân viên vào đây phân cách bởi dấu ','"></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <v-checkbox  hide-details="auto" v-model="form.allDepartment" label="Thông báo cho tất cả nhân viên TS"></v-checkbox>
                            </v-col>
                            <v-col cols="4">
                                <v-checkbox  hide-details="auto" v-model="form.isFeature" label="Hiển thị pop-up"></v-checkbox>
                            </v-col>
                            <v-col cols="4">
                                <v-checkbox  hide-details="auto" v-model="form.hidden" label="Ẩn"></v-checkbox>
                            </v-col>
                            <v-col cols="4">
                                <v-checkbox hide-details="auto" v-model="form.erp" label="Hiển thị ERP"></v-checkbox>
                            </v-col>
                            <v-col cols="6">
                                <date-picker2 :outlined="true" v-model="form.validFrom" :range="false" :width="1000" label="Hiệu lực từ"></date-picker2>
                            </v-col>
                            <v-col cols="6">
                                <date-picker2 :outlined="true" v-model="form.popUpExpire" :range="false" :width="1000" label="Hiệu lực đến"></date-picker2>
                            </v-col>
                            <!-- <v-col cols="6">
                                <v-text-field v-model="form.avatar" label="Avatar">
                                </v-text-field>
                                <span>Bạn có thể up ảnh tại <a href="https://upanh.org/?lang=vi" target="_blank">uphinh.vn</a></span> <br>
                                <span>Bạn có thể up ảnh tại <a href="https://imagecompressor.com/vi/" target="_blank">Nén ảnh nếu dung lượng quá lớn</a></span>
                            </v-col> -->
                        </v-row>
                    </v-col>
                </v-row>
                </v-form>
            </v-card-text>
            <v-spacer></v-spacer>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" type="submit" form="form"> Lưu</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import http from '@/components/services/http-common';
import api from '@/components/services/ApiServices';
import Editor from '@/components/Editor/Editor.vue';
const CategoryURL = 'contentCategories'

export default {
    props:["value", "item", "employee"],
    components:{
        Editor
    },
    watch:{
        value: {
            handler(val){
                if(val)
                {
                    if(this.employee){
                    }
                    if(this.isEdit)
                        this.fetch().then(()=>{
                            this.fetchData = false;
                        });
                }
                else{
                    this.close();
                }
            }
        },
        employeeCodeMass: {
            handler(val){
                if(val != null)
                {
                    if(val)
                        if(val.length > 0)
                            this.employeesPopUpList = val.split(",");
                }
            }
        },

        // FETCHING DATA
    },
    computed: {
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        isEdit() {
            return !!this.item;
        }
    },
    data() {
        return {
            ROOT_URL: "contents/",
            EMPLOYEE_FETCH: "employees/search",

            employeeCodeMass: "",
            isValid: true,
            fetchData: true,
            categories: [],
            departments: [],
            employees: [],
            departmentsPopUpList: [],
            employeesPopUpList: [],
            departmentTypes:[],
            link: '',
            linkName: '',
            form: {
                avatar:null,
                avatarFile: null,
                title:null,
                brief: "Tóm tắt nội dung bài viết",
                body: "Nội dung bài viết",
                contentCategoryCode: "KHAC",
                isFeature:false,
                validFrom:"",
                popUpExpire:"",
                avartarFormFile: undefined,
                avatarBlob: "",
                allDepartment: false,
                departmentTypeCode: null,
                departmentsPopUp:"",
                hidden:false,
                erp:true,
            },
            defaultForm:{
                avatar:null,
                avatarFile: null,
                title:null,
                brief: "Tóm tắt nội dung bài viết",
                body: "Nội dung bài viết",
                contentCategoryCode: "KHAC",
                isFeature:false,
                validFrom:"",
                popUpExpire:"",
                avartarFormFile: undefined,
                avatarBlob: "",
                allDepartment: false,
                departmentTypeCode: null,
                departmentsPopUp:"",
                hidden:false,
                erp:true,
            }
        }
    },
    methods: {
        addLink() {
            let linkString = `<a href="${this.link}">${this.linkName}</a><br> \n`
            this.form.brief = linkString + this.form.brief
        },
        customFilter (item, queryText, itemText) {
            const textOne = item.code.toLowerCase()
            const textTwo = item.name.toLowerCase()
            const searchText = queryText.toLowerCase()

            return textOne.indexOf(searchText) > -1 ||
            textTwo.indexOf(searchText) > -1
        },
        fetchCategory(){
            let url = `${CategoryURL}/fetch`
            return http.get(url).then(res => {
                this.categories = res.data;
            })
        },
        fetch(){
            return http.get(this.ROOT_URL+this.item.code).then(res => {
                console.log(res.data)
                this.form = Object.assign({}, res.data);
                if(this.departmentsPopUp != null)
                    this.departmentsPopUpList = this.form.departmentsPopUp.split(",");
                if(this.form.employeesPopUp != null){
                    
                    this.employeeCodeMass = this.form.employeesPopUp;
                    console.log(this.form.employeesPopUp.split(","))
                }
            })
        },
        fetchDepartment (){
            return api.fetchDepartment().then(res => {this.departments = res.data})
        },
        fetchEmployee(val) {
            return http.get(this.EMPLOYEE_FETCH).then(res => {
                this.employees = res.data;
            });
        },

        saveData() {
            this.$refs.form.validate();
            this.form.departmentsPopUp = this.departmentsPopUpList.join(",");
            this.form.employeesPopUp = this.employeesPopUpList.join(",");
            let formData = new FormData();
            if(this.isValid)
            {
                if(!this.isEdit){
                    
                    for (const [key, value] of Object.entries(this.form)) {
                        if(value == null){
                            formData.append(key, '');
                        }else {
                            formData.append(key, value);
                        }
                    }
                    return http.post(this.ROOT_URL, formData, {
                        headers:  { 
                            "Content-Type": "multipart/form-data" 
                            },
                    }).then(res =>{
                        this.$emit("fetch");
                        this.dialog = false;
                    })
                }else {
                    for (const [key, value] of Object.entries(this.form)) {
                        if(value == null){
                            formData.append(key, '');
                        }else {
                            formData.append(key, value);
                        }
                    }
                    return http.put(this.ROOT_URL+ this.item.code, formData).then(res =>{
                        this.$emit("fetch");
                        this.dialog = false;
                    })
                }
            }
        },
        close(){
            this.form = Object.assign({}, this.defaultForm);
            this.$refs.form.resetValidation();
            this.fetchData=true;
        }
    },
    created() {
        this.fetchCategory();
        this.fetchDepartment();
        this.fetchEmployee();
        http.get("departmentTypes/fetch").then(r => {
            console.log(r.data)
            this.departmentTypes = r.data
        })
    }
    
}
</script>
<style scoped>
/* form .col {
    padding-top: 0px;
    padding-bottom: 0px;
} */
</style>